import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";

export const CodeSerializer = ({ node }) => {
  const { code, language } = node;
  if (!code) {
    return null;
  }
  return (
    <SyntaxHighlighter language={language || "text"}>{code}</SyntaxHighlighter>
  );
  //   return <pre data-language={node.language}>
  //     <code>{node.code}</code>TEST
  //   </pre>
};
