import React from "react";

import { FigureSerializer } from "./FigureSerializer";
import { LatexSerializer } from "./LatexSerializer";
import { CodeSerializer } from "./CodeSerializer";
import { YouTubeSerializer } from "./YouTubeSerializer";

const serializers = {
  types: {
    /* eslint-disable-next-line react/display-name */
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    mainImage: FigureSerializer,
    latex: LatexSerializer,
    /* eslint-disable-next-line react/display-name */
    code: CodeSerializer,
    youtube: YouTubeSerializer,
  },
};

export default serializers;
