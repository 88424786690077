import React, { useState, useMemo } from "react";
import "katex/dist/katex.css";
import KaTeX from "katex";

export const LatexSerializer = ({ node }) => {
  const latex = node.body || "";
  const isInline = node.layout === "inline";
  const [html, setHtml] = useState("");
  useMemo(() => {
    setHtml(
      KaTeX.renderToString(latex, {
        displayMode: !isInline,
        throwOnError: false,
      })
    );
  }, [latex, isInline]);

  if (isInline) {
    return <span dangerouslySetInnerHTML={{ __html: html }} />;
  }
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
